import React, { useState, useEffect } from "react";
import { resendOtpCode } from "../../services/users";
import { Row, Button, Modal } from "antd";
import OtpInput from "react-otp-input";
import { t } from "../../helpers/translation";
import { authenticateOtp, cancelUser } from "../../services/users";
import moment from "moment";

const SmsAuthentication = ({ currentUser }) => {
  const [otp, setOtp] = useState("");
  const [stateMessage, setStateMessage] = useState("");
  const [disableOtpInput, setDisableOtpInput] = useState(false);
  const [disableOtpResend, setDisableResend] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const initialTime = 5 * 60;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [timeRemainingDisplay, setTimeRemainingDisplay] = useState(moment().startOf('day').add(initialTime, "seconds").format("mm:ss"));
  let timerInterval = null;

  const countdown = () => {
    setTimeRemaining((prevTime) => {
      setTimeRemainingDisplay(moment().startOf('day').add(prevTime - 1, "seconds").format("mm:ss"));
      if (prevTime - 1 === 0) {
        clearInterval(timerInterval);
        setDisableResend(false);
        return 0;
      }
      return prevTime - 1;
    });
  };

  useEffect(() => {
    timerInterval = setInterval(countdown, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  const handleResend = async () => {
    setOtp("");
    setStateMessage("");
    setIsLoading(true);

    try {
      const res = await resendOtpCode(currentUser);
      if (res?.data?.success) {
        setIsLoading(false);
        setStateMessage(res.data.message);
        setDisableResend(true);
        setTimeRemaining(initialTime);
        setTimeRemainingDisplay(moment().startOf('day').add(initialTime, "seconds").format("mm:ss"));
        timerInterval = setInterval(countdown, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response.status == 423) {
        setDisableOtpInput(true);
        Modal.error({
          title: window.i18n.t("new_user.sms_auth_error"),
          content: error?.response?.data?.message,
          okText: window.i18n.t("new_user.confirm"),
          onOk: redirectToLogin,
        });
      } else {
        Modal.error({
          title: window.i18n.t("new_user.sms_auth_error"),
          content: error?.response?.data?.message,
          okText: window.i18n.t("new_user.confirm"),
        });
      }
    }
  };

  const handleChange = async (otp) => {
    setOtp(otp);
    setStateMessage("");
    setDisableSubmit(otp.length !== 4);
  };

  const redirectToLogin = () => {
    window.location.href = `/users/sign_in`;
  };

  const onCancel = async () => {
    const res = await cancelUser();
    if (res && res.data && res.data.success) {
      window.location.href = "/";
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await authenticateOtp({
        user: {
          otp_code: otp,
        },
      });
      if (res?.data?.success) {
        window.location.href = `${window.location.origin}/users/profiles/edit`;
      } else {
        Modal.error({
          title: window.i18n.t("new_user.sms_auth_error"),
          content: res?.data?.message,
          okText: window.i18n.t("new_user.confirm"),
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (error?.response.status == 423) {
        setDisableOtpInput(true);
        Modal.error({
          title: window.i18n.t("new_user.sms_auth_error"),
          content: error?.response?.data?.message,
          okText: window.i18n.t("new_user.confirm"),
          onOk: redirectToLogin,
        });
      } else {
        Modal.error({
          title: window.i18n.t("new_user.sms_auth_error"),
          content: error?.response?.data?.message,
          okText: window.i18n.t("new_user.confirm"),
        });
      }
      setIsLoading(false);
    }
  }

  return (
    <div className="flex flex-col justify-between mx-5 my-10 h-112 sms-auth">
      <h1 className="text-2xl font-body text-textColor-300">
        {window.i18n.t("new_user.sms_authenticate_title")}
      </h1>
      <div className="text-center mt-10">
        <p className="font-body font-sm text-textColor-300">
          {window.i18n.t("new_user.sms_authenticate_description")}
        </p>
        <OtpInput
          containerStyle="align-center justify-center mb-10"
          inputStyle="inputStyle"
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span className="w-5" />}
          isDisabled={disableOtpInput}
          shouldAutoFocus
          isInputNum
        />
        {stateMessage && (
          <p className="text-green-300 mt-5">
            {stateMessage}
          </p>
        )}
        <p>
          <Button
            onClick={handleResend}
            disabled={disableOtpResend}
            loading={isLoading}
            type="link"
          >
            {window.i18n.t("new_user.resend_otp_button")}
            {disableOtpResend && (
              <>
                &nbsp; (
                {timeRemainingDisplay}
                &nbsp;
                {window.i18n.t("new_user.resend_otp_button_until")})
              </>
            )}
          </Button>
        </p>
      </div>
      <Row>
        <Button
          className="btn btn-default"
          onClick={handleSubmit}
          variant="no-style"
          disabled={disableSubmit}
          loading={isLoading}
        >
          {window.i18n.t("new_user.confirm_otp_button")}
        </Button>
        <Button
          className="btn btn-secondary mt-6"
          onClick={onCancel}
        >
          {t("new_user.cancel_button")}
        </Button>
      </Row>
    </div>
  );
};

export default SmsAuthentication;
