import React from 'react'
import { Modal, Button, Typography, Space } from 'antd'
import { t } from '../../helpers/translation'
import styles from './styles'
import { micromark } from 'micromark';

export const ErrorDialog = ({ title, message, visible, onRedo, onCancel }) => {
  message = micromark(message);
  message = message.replaceAll("\\n", "<br/>");
  return (
    <Modal
      title={title}
      visible={visible}
      style={{ maxWidth: 400 }}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <Typography class="text-center mb-4" dangerouslySetInnerHTML={{ __html: message }} />
      <Space direction='vertical' style={styles.btnContainer}>
        <Button style={styles.btnPrimary} onClick={onRedo}>
          <Typography style={styles.btnPrimaryText}>{t('orders.error_redo_order')}</Typography>
        </Button>
        <Button style={styles.btnSecondary} onClick={onCancel}>
          <Typography>{t('orders.error_cancel_order')}</Typography>
        </Button>
      </Space>
    </Modal>
  )
}
