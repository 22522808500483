import React, {useState, useEffect} from 'react';
import { Modal } from "antd"
import Picker from 'react-mobile-picker'
import { yearsList, monthsList, daysList } from '../../helpers/customers'


const Dob = ({ month, year, day, setMonth, setYear, setDay, showDOBModal, setShowDOBModal, form}) => {
  const [pickerValue, setPickerValue] = useState({
    year: year,
    month: month,
    day: day
  })

  const [options, setOptions] = useState({
    year: yearsList(),
    month: monthsList(),
    day: daysList(pickerValue.month, pickerValue.year)
  })

  useEffect(() => {
    const days = daysList(pickerValue.month, pickerValue.year)
    const lastDay = days.length

    if (lastDay < pickerValue.day) {
      setPickerValue(prevValues => ({
        ...prevValues,
        day: lastDay
      }))
    }

    setOptions(prevValues => ({
      ...prevValues,
      day: days
    }))
  }, [pickerValue.month, pickerValue.year])

  const handleOk = () => {
    setYear(pickerValue.year)
    setMonth(pickerValue.month)
    setDay(pickerValue.day)
    form.validateFields(['birthday'])
    setShowDOBModal(false)
  }
  
  const handleCancel = () => {
    setShowDOBModal(false)
  }

  return (
    <Modal
      visible={showDOBModal}
      onOk={handleOk}
      onCancel={handleCancel}
      title={window.i18n.t("new_user.enter_date_of_birth")}
      okText={window.i18n.t("new_user.set_text")}
      width={550}
      cancelButtonProps={{style: { display: "none" } }}
    >
      <Picker value={pickerValue} onChange={setPickerValue} height={200} wheelMode="normal">
        {Object.keys(options).map(name => (
          <Picker.Column key={name} name={name}>
            {options[name].map(option => (
              <Picker.Item key={option} value={option}>
                {option}
              </Picker.Item>
            ))}
          </Picker.Column>
        ))}
      </Picker>
    </Modal>
  );
}

export default Dob;
