import { Col, Row, Typography, Spin, Button } from 'antd'
import { isEmpty, debounce } from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'
import brandSvg from '../../../assets/images/brand.svg'
import categorySvg from '../../../assets/images/category.svg'
import whiteBrandSvg from '../../../assets/images/white_brand.svg'
import whiteCategorySvg from '../../../assets/images/white_category.svg'
import List from '../../components/ProductList/List'
import { t } from '../../helpers/translation'
import Brand from '../Brand/index'
import Category from '../Category/index'
import SearchForm from './SearchForm'
import styles from './styles.js'

const ProductSearch = (current_user) => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(searchParams.entries());
  const searchValue = params.q || ''

  const axios = require('axios');
  const [products, setProducts] = useState([])
  const [searchResult, setSearchResult] = useState({})
  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [searchString, setSearchString] = useState(null)
  const [loading, setLoading ] = useState(false)
  const [searchType, setSearchType] = useState(null)
  const [pagination, setPagination] = useState(0)
  const [showNextButton, setShowNextButton] = useState(false)

  useEffect(() => {
    setSearchType(params?.selected_type || 'default')
    if (searchValue) {
      setSearchType('default')
      setLoading(true)
      setSearchString(searchValue)
      debounceSearch(searchValue)
    }
  }, [searchValue])

  useEffect(() => {
    axios.get('/ec_users/brands/fetch_brands', {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
      .then(function (response) {
        setBrands(response.data.brands)
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  useEffect(() => {
    axios.get('/ec_users/categories/fetch_categories', {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
      .then(function (response) {
        setCategories(response.data.categories)
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const handleFetchProducts = (q, append = false) => {
    axios.get(`/ec_users/products/fetch_products?string=${encodeURIComponent(q)}&page=${pagination + 1}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
      .then(function (response) {
        if (!isEmpty(response.data?.products)) {
          if(append) {
            const newListProducts = products.concat(response.data?.products || [])
            setProducts(newListProducts)
          } else {
            setProducts(response.data?.products || [])
          }
        }
        setSearchResult(response.data)
        setPagination(response.data?.pager?.current_page);
        setShowNextButton(!response.data?.pager?.last_page);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const debounceSearch = useCallback(debounce((nextValue) => handleFetchProducts(nextValue), 300), [])

  const handleSearch = (e) => {
    setLoading(true)
    setSearchString(e.target.value)
    debounceSearch(e.target.value)
  }

  return (
    <>
      <Row style={styles.row}>
        <Col style={styles.col}>
          <img src='../../images/search.svg' />
        </Col>
        <Col>
          <Typography style={styles.text}>
            {t('product_search.find_product')}
          </Typography>
        </Col>
      </Row>
      <SearchForm
        value={searchString}
        onSearch={handleSearch}
        setSearchType={setSearchType}
        current_user={current_user}
      />
      {!current_user.current_user && 
        <Row>
          <Col style={styles.typeCol}>
            <Typography style={styles.message}>
              {t('product_search.home_member_exclusive_brands_disclaimer')}
            </Typography>
          </Col>
        </Row> 
      }
      {isEmpty(searchString) &&
         <Row style={styles.typeRow}>
          <Col style={styles.typeCol}>
            <button onClick={() => setSearchType('brand')}>
              <div style={searchType == 'brand' ? styles.clickedButton : styles.defaultButton}>
                <img src={searchType == 'brand' ? whiteBrandSvg : brandSvg} />
              </div>
            </button>
            <Typography>
              {t('product_search.find_by_brand')}
            </Typography>
          </Col>
          <Col style={styles.typeCol}>
            <button onClick={() => setSearchType('category')}>
              <div style={searchType == 'category' ? styles.clickedButton : styles.defaultButton}>
                <img src={searchType == 'category' ? whiteCategorySvg : categorySvg} />
              </div>
            </button>
            <Typography>
              {t('product_search.search_by_category')}
            </Typography>
          </Col>
        </Row>
      }
      
      {!isEmpty(searchString) &&
        searchResult.products?.length == 0 &&
        searchResult.brands?.length == 0 &&
        searchResult.categories.length == 0 &&
          <Row style={styles.errorRow}>
            <Typography
              style={styles.searchError}>
              {t('product_search.no_item_found')}
            </Typography>
          </Row>
      }

      <Spin spinning={loading}>
        {searchType == 'brand'? (
          <Brand brands={brands} />
        ) : ''}
        {searchType == 'category'? (
          <Category categories={categories} />
        ) : ''}
 
        {!isEmpty(searchResult?.products) &&
          <>
            <List products={products} />
            { showNextButton &&
              <div className="mx-auto w-40">
                <Button type="primary" onClick={() => handleFetchProducts(searchString, true)} className="primary-btn-bg mb-10">
                  {t('product_search.next_page')}
                </Button>
              </div>
            }
          </>
        }
        {!isEmpty(searchResult?.brands) &&
          <Brand brands={searchResult?.brands} />
        }
        {!isEmpty(searchResult?.categories) &&
          <Category categories={searchResult?.categories} />
        }
      </Spin>
    </>
  )
}

export default ProductSearch
