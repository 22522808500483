import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Row, Radio, Checkbox, Col, Button, Typography } from "antd";
import Dob from "./Dob";
import { t } from '../../helpers/translation';
import styles from './styles';
import { japaneseRegex, katakanaRegex } from '../../utils/validationPattern';
import { cancelUser } from '../../services/users'
import './style.scss'

const ProfileForm = ({ onFinish, day, setDay, month, setMonth, year, setYear, user, isNewRegister, lineLinked}) => {
  const [form] = Form.useForm();
  const [showDOBModal, setShowDOBModal] = useState(false)

  useEffect(() => {
    form.setFieldsValue({ 
      receive_promotional_message: !isNewRegister && user ? user.receive_promotional_message : true,
    })
    const keys = ['first_name', 'last_name', 'first_name_kana', 'last_name_kana', 'gender', 'birthday', 'email']
    for(let key of keys) {
      if(!user?.[key] || user?.[key] == '') continue
      form.setFieldsValue({ [key]: user?.[key] })
    }
    
  }, [])

  const onCancel = async () => {
    if(!isNewRegister) {
      window.location.href = '/ec_users/accounts';
      return
    }
    const res = await cancelUser()
    if (res && res.data && res.data.success) {
      window.location.href = '/';
    }
  }

  const handleLineLinking = () => {
    if(!lineLinked){
      window.location.href = '/users/line_linking?from_account_page=true';
      return
    }
    else{
      Modal.info({
        title: '既にLINEアカウントと連携しています。',
        okText: '確認',
        onOk() {},
      });
    }
  }

  const validateBirthday = () => {
    const parsedMonth = String(month).padStart(2, '0')
    const parsedDay = String(day).padStart(2, '0')
    const dateTimestamp = Date.parse(`${year}-${parsedMonth}-${parsedDay}`)

    if (isNaN(dateTimestamp)) {
      return Promise.reject(new Error(t("new_user.validation.invalid_birthday")));
    }

    const dateObj = new Date(dateTimestamp)
    if (dateObj.getFullYear() !== year ||  dateObj.getMonth() + 1 !== month || dateObj.getDate() !== day) {
      return Promise.reject(new Error(t("new_user.validation.invalid_birthday")));
    }

    const today = new Date()
    if (dateObj > today) {
      return Promise.reject(new Error(t("new_user.validation.invalid_birthday")));
    }

    return Promise.resolve();
  }

  return (
    <Form
      layout="vertical"
      form={form}
      name="update"
      onFinish={onFinish}
      className='profile-form'
    >
      <Form.Item
        name="last_name"
        label={t("new_user.last_name_kanji")}
        rules={[
          {
            required: true,
            message: t("common.validates.required", { field: t("new_user.last_name_kanji") }),
          },
          {
            pattern: japaneseRegex,
            message: t("new_user.validation.invalid_name", { field: t("new_user.last_name_kanji") }),
          },
          {
            max: 50,
            message: t("new_user.validation.invalid_name", { field: t("new_user.last_name_kanji") }),
          },
        ]}
        hasFeedback
      >
        <Input className="rounded-input" style={styles.inputHeight} placeholder={t("new_user.last_name_kanji")} />
      </Form.Item>

      <Form.Item
        name="first_name"
        label={t("new_user.first_name_kanji")}
        rules={[
          {
            required: true,
            message: t("common.validates.required", { field: t("new_user.first_name_kanji") }),
          },
          {
            pattern: japaneseRegex,
            message: t("new_user.validation.invalid_name", { field: t("new_user.first_name_kanji") }),
          },
          {
            max: 50,
            message: t("new_user.validation.invalid_name", { field: t("new_user.first_name_kanji") }),
          },
        ]}
        hasFeedback
      >
        <Input className="rounded-input" style={styles.inputHeight} placeholder={t("new_user.first_name_kanji")} />
      </Form.Item>

      <Form.Item
        name="last_name_kana"
        label={t("new_user.last_name_kana")}
        rules={[
          {
            required: true,
            message: t("common.validates.required", { field: t("new_user.last_name_kana") }),
          },
          {
            pattern: katakanaRegex,
            message: t("new_user.validation.invalid_name_kana", { field: t("new_user.last_name_kana") }),
          },
          {
            max: 50,
            message: t("new_user.validation.invalid_name_kana", { field: t("new_user.last_name_kana") }),
          },
        ]}
        hasFeedback
      >
        <Input className="rounded-input" style={styles.inputHeight} placeholder={t("new_user.last_name_kana")} />
      </Form.Item>

      <Form.Item
        name="first_name_kana"
        label={t("new_user.first_name_kana")}
        rules={[
          {
            required: true,
            message: t("common.validates.required", { field: t("new_user.first_name_kana") }),
          },
          {
            pattern: katakanaRegex,
            message: t("new_user.validation.invalid_name_kana", { field: t("new_user.first_name_kana") }),
          },
          {
            max: 50,
            message: t("new_user.validation.invalid_name_kana", { field: t("new_user.first_name_kana") }),
          },
        ]}
        hasFeedback
      >
        <Input className="rounded-input" style={styles.inputHeight} placeholder={t("new_user.first_name_kana")} />
      </Form.Item>

      <Form.Item
        name="gender"
        label={t("new_user.gender")}
      >
        <Radio.Group className="profile__input-gender">
          <Row>
            <Col span="8">
              <Radio value="men">{t("new_user.male")}</Radio>
            </Col>
            <Col span="8">
              <Radio value="women">{t("new_user.female")}</Radio>
            </Col>
            <Col span="8">
              <Radio value="not_to_say">{t("new_user.not_to_say")}</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
      
      <Form.Item
        name="birthday"
        label={t("new_user.birthday")}
        rules={[
          {validator: validateBirthday}
        ]}
      >
        <Input
          className="rounded-input"
          style={styles.inputHeight}
          onClick={() => setShowDOBModal(true)}
          value={`${year}-${month}-${day}`}
        />
        <Dob
          showDOBModal={showDOBModal}
          setShowDOBModal={setShowDOBModal}
          day={day}
          month={month}
          year={year}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
          form={form}
        />
      </Form.Item>

      <Form.Item
        name="email"
        label={t("new_user.email")}
        rules={[
          {
            type: "email",
            message: t("new_user.not_valid_email"),
          },
          {
            required: true,
            message: t("new_user.enter_email"),
          },
          {
            max: 255,
            message: t("new_user.max_lenth", { length: 255 }),
          },
        ]}
        hasFeedback
      >
        <Input className="rounded-input" style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="receive_promotional_message"
        valuePropName="checked"
        style={{ marginBottom: 0 }}
      >
        <Checkbox>
          {t("new_user.receive_promotional_message")}
        </Checkbox>
      </Form.Item>
      <Typography className="text-xs mb-4">{t("new_user.guidePromotional")}</Typography>
      { !isNewRegister &&
        (<div>
          <Checkbox checked={lineLinked}>
            <a onClick={handleLineLinking}>
              {t('new_user.line_linked')}
            </a>
          </Checkbox>
          <Typography className="text-xs mb-4">＊LINE連携をご希望の方は「LINE連携」の文言をクリックしてください。</Typography>
        </div>)
      }
      <Form.Item shouldUpdate className="mt-4 mb-0">
        {() =>(
          <Row className="mt-4">
            <Button
              className="btn btn-default"
              variant="primary"
              htmlType="submit" 
              disabled={ !!form.getFieldsError().filter(({ errors }) => errors.length).length }
            >
              {isNewRegister ? t("new_user.ok") : t("new_user.updateBtn")}
            </Button>
          </Row>
        )}
      </Form.Item>

      <Row className="mt-2">
        <Button className="btn btn-default btn-gray" onClick={onCancel}>
          {t('new_user.cancel_button')}
        </Button>
        { isNewRegister && <Typography>{t("new_user.guideCancellation")}</Typography>}
      </Row>
    </Form>
  );
};

export default ProfileForm;
